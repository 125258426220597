<template>
  <div class="container">
    <div class="row bg-white p-2">
      <div class="col-md-12 d-flex justify-content-between">
        <p style="font-size: 1.3rem;">Bank Video</p>
        <!-- <div>
          <button type="button" class="btn btn-outline-primary ml-auto mr-2">
            <i class="fa-solid fa-plus"></i> Tambah
          </button>
          <button type="button" class="btn btn-outline-primary ml-auto">Primary</button>
        </div> -->
      </div>
      <div class="form-group container">
        <p class="mt-1"><strong>Judul<span class="text-danger"> (*)</span></strong></p>
        <input type="text" class="form-control" id="usr">

        <p class="mt-1"><strong>Deskripsi<span class="text-danger"> (*)</span></strong></p>
        <quill-editor v-model="item.content" :options="editorOptions" class="mb-1"></quill-editor>

        <p class="mt-1"><strong>Durasi waktu<span class="text-danger"> (*)</span></strong></p>
        <input type="text" class="form-control" id="usr" />

        <p class="mt-1"><strong>File video<span class="text-danger"> (*)</span></strong></p>
        <input type="file" class="form-control" id="usr">

        <div class="mt-5">
          <button type="button" class="btn btn-success mr-1">Simpan</button>
          <button type="button" class="btn btn-outline-success mr-1">Simpan dan Buat Lagi</button>
          <button type="button" class="btn btn-outline-primary">Reset</button>
        </div>
        <br>
        <hr>
        <br>
        <p>Note:</p>
        <p>Tanda bintang<strong><span class="text-danger"> (*)</span></strong> berarti wajib diisi/pilih</p>

      </div>
    </div>
  </div>
</template>

<script>
  import {
    quillEditor
  } from 'vue-quill-editor';
  import 'quill/dist/quill.core.css';
  import 'quill/dist/quill.snow.css';
  import 'quill/dist/quill.bubble.css';

  export default {
    components: {
      quillEditor,
    },
    data() {
      return {
        item: {
          title: '',
          content: '',
          minutes: '',
        },
        editorOptions: {
          theme: 'snow',
          placeholder: 'Enter the description here...',
        },
      };
    },
    methods: {
      handleFileUpload(event) {
        const file = event.target.files[0];
      },
    },
  };
</script>

<style>
  /* Add any additional styling for your form */
</style>